import { Link } from "react-router-dom";

import {sanitizeProductDescription} from "../tools"
import image_not_available from "../assets/service/no-picture-available-icon.png"

function ProductsCard(props) {
    return (
        <div className="col-lg-3 col-md-4 col-sm-6 p-2">
            <div className="card h-100">
                <Link to={"/product/" + props.item._id} >
                    <div className="ratio ratio-1x1">
                        <img className="card-img-top p-2" src={props.item._source.images.hero[0].full} onError={(e)=>{e.target.src=image_not_available}} alt="product" />
                    </div>
                </Link>

                <div className="card-body d-flex flex-column">
                    <Link to={"/product/" + props.item._id} className="text-decoration-none">
                        <h5 className="text-dark card-title"> {props.item._source.name} </h5>
                    </Link> 
                    <p className="card-text"> { (props.item._source.price.old_value && (props.item._source.price.old_value > props.item._source.price.value)) ?
                        <span> <big>  $ {props.item._source.price.value} </big> <span className="text-decoration-line-through"> $ {props.item._source.price.old_value} </span> </span>
                        : <big>  $ {props.item._source.price.value} </big>
                    }
                    </p>

                    <p className="card-text"> {sanitizeProductDescription(props.item._source.description.text)} </p>

                    <div className="row text-muted mt-auto">
                        <div className="col-6">
                            {/* <p className="card-text"> In-stock </p> */}
                        </div>
                        <div className="col-6 text-end">
                            <p className="card-text"> by <span className="fw-bold">{props.item._source.seller} </span> </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>             
    );
}

export default ProductsCard;

