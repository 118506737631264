import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';

import ErrorPage from "./ErrorPage"
import ProductCard from './ProductCard';
import {BASE_URL, sanitizeProductDescription} from "../tools"
import image_not_available from "../assets/service/no-picture-available-icon.png"


function SimilarProducts() {
    let params = useParams();
    const [loadStatus, setLoadStatus] = useState(0);
    const [similarList, setSimilarList] = useState([]);

    useEffect(() => {
        setLoadStatus(0);

        axios.get(BASE_URL + "/similar?id=" + params.productId)
            .then((response) => {
                // debug_slow_down();
                setLoadStatus(1);
                setSimilarList(response.data.results);
        });
    }, [params]);

    switch (loadStatus) {
        case 1:
            return (
                <div className="container-fluid py-2">
                    <div className="d-flex flex-row flex-nowrap overflow-auto">
                        {similarList.map((item) => { return ( 
                            <ProductCard item={item} key={item._id}/> 
                        ) }) }
                    </div>
                </div>
        );
        default:
            return (
                <section id="search-results"> 
                    <div className="d-flex justify-content-center py-5">
                        <div className="spinner-border mx-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </section>
            );
    }
}


function ImgCarousel(props) {
    return (
        <div id="ProductCarousel" className="carousel carousel-dark slide py-3" data-bs-ride="carousel">
            <div className="carousel-indicators">
                <button type="button" 
                        data-bs-target="#ProductCarousel"
                        data-bs-slide-to="0"
                        className="active"
                        aria-current="true"
                        aria-label="Slide 1">
                </button>
                {props.images.map((img, idx) => { return ( 
                    <button type="button"
                            key={"carousel_button_" + (idx + 1)}
                            data-bs-target="#ProductCarousel"
                            data-bs-slide-to={idx + 1}
                            aria-label={"Slide " + ( idx + 2 )}
                    ></button> 
                ) }) }
            </div>
            <div className="carousel-inner">
                <div className="carousel-item active ratio ratio-16x9">
                    <img src={props.hero.full} className="d-block w-100" alt="product" onError={(e)=>{e.target.src=image_not_available}}/>
                </div>
                {props.images.map((img, idx) => { return ( 
                    <div className="carousel-item ratio ratio-16x9" key={"carousel_img_" + (idx + 1)}>
                        <img src={img.url} className="d-block w-100" alt="product" onError={(e)=>{e.target.src=image_not_available}}/>
                    </div>
                 ) }) }
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#ProductCarousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#ProductCarousel" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
  )
}


function ProductPage() {
    let params = useParams();
    const [loadStatus, setLoadStatus] = useState(0);
    const [productData, setProductData] = useState(null);

    useEffect(() => {
        setLoadStatus(0);

        axios.get(BASE_URL + "/get_product?id=" + params.productId)
            .then((response) => {
                // debug_slow_down();
                if (response.data.found) {
                    setLoadStatus(1);
                    setProductData(response.data.results);
                } else {
                    setLoadStatus(-1);
                }
        });
    }, [params]);

    switch (loadStatus) {
        case 0:
            return (
                <section id="search-results"> 
                    <div className="d-flex justify-content-center py-5">
                        {// TODO: loading in the middle of the screen !
                        }
                        <div className="spinner-border mx-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </section>
            );
        case 1:
            return (
            <section id="product-page">
                <div className="container py-5">
                    <div className="row">

                        <div className="col-lg-8 col-md-6 col-12">
                            <ImgCarousel hero={productData._source.images.hero[0]} images={productData._source.images.gallery}/>
                        </div>

                        <div className="col-lg-4 col-md-6 col">
                            <h3 className="text-dark"> {productData._source.name} </h3>

                            <div className="row text-muted py-2">
                                <div className="col-6">
                                    <p className=""> In-stock </p>
                                </div> 
                                
                                <div className="col-6 text-end">
                                    <p className=""> by {productData._source.seller} </p>
                                </div>
                            </div>
                                    
                            <p className="py-2"> { (productData._source.price.old_value && (productData._source.price.old_value > productData._source.price.value)) ?
                                <span> <big>  $ {productData._source.price.value} </big> <span className="text-decoration-line-through"> $ {productData._source.price.old_value} </span> </span>
                                : <big>  $ {productData._source.price.value} </big>
                            }
                            </p>

                            <p> {sanitizeProductDescription(productData._source.description.text, false)} </p>

                            <a className="text-decoration-none" href={productData._source.link} target="_blank" rel="noreferrer noopener"> 
                                <h5 className="text-black pt-3">  Learn More | Buy Product </h5> 
                            </a> 

                        </div>

                    </div>

                    <h4 className="text-dark pt-4 pb-2"> Similar Products </h4>
                    <SimilarProducts/>

                </div>
            </section>
        );
        
        default:
            return (
                <ErrorPage />
            );
    }
}

export default ProductPage;