import React  from 'react';

function Footer() {
    return (        
      <footer id="sticky-footer" className="fixed-bottom py-2 bg-dark text-white-50">
            <div className="container-xl">
                <div className="row">
                    <div className="col-6 justify-content-start d-flex">
                        <small> &copy;1Goggle </small>
                    </div>
                    <div className="col-6 justify-content-end d-flex">
                        <ul className="nav col-md-4 list-unstyled">
                            <li className="ms-3"> <a className="text-white-50" href="/#"> <span className="bi-instagram"></span>  </a> </li>
                            <li className="ms-3"> <a className="text-white-50" href="/#"> <span className="bi-twitter"></span>  </a> </li>
                            <li className="ms-3"> <a className="text-white-50" href="/#"> <span className="bi-facebook"></span>  </a> </li>
                        </ul>
                    </div>
                </div>
            </div>
      </footer>
);
}

export default Footer;