import React, {useState} from 'react';
import { Routes, Route } from "react-router-dom";

import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import SearchBar from './components/SearchBar';
import ProductsList from './components/ProductsList';
import ProductPage from './components/ProductPage';
import ErrorPage from './components/ErrorPage';

function App() {

	const [searchResultProps, setSearchResultProps] = useState({});

  return (
    <div className="App">
      <Header/>
      <Routes>
				<Route path="product/:productId" element={<ProductPage/>}/>
        <Route path="/" element={ <SearchBar searchResults={searchResultProps}/> }>
            <Route index element={ <HomePage/> }/>
            <Route path="search" element={ <ProductsList searchUpd={setSearchResultProps}/> } />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
