import React from 'react';
import { Link } from "react-router-dom";

function Header() {
    return (
    <header className="sticky-top">
      <nav className="navbar px-4 navbar-expand-lg navbar-light bg-light">
        <div className="container-xl text-dark">
            <div className="me-auto">
                <Link to="/"  className="navbar-brand"> <big> 1Goggle </big> </Link>
            </div>

            <div className="ms-auto">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
            
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="/#">Partners</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#">Contact</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#">About Us</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/#">Sign In</a>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
      </nav>
    
    </header>
);
}

export default Header;