export const BASE_URL = "https://api.onegoggle.com"

// Artificially slow down for debug purposes
export function debug_slow_down(its=1e20) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e20; i++) {
        if ((new Date().getTime() - start) > 4000){
        break;
        }
    }
}

export function sanitizeProductDescription(html, limit_text=100)
{
   let tmp = document.createElement("DIV");
   tmp.innerHTML = html;

   let ret_str = tmp.textContent || tmp.innerText || "";

   if (limit_text) {
      ret_str = ret_str.substring(0, 100) + "...";
   }

   return ret_str
}

export default function _(){}