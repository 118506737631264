import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from "react-router-dom";


function SupplierFilter(props) {

    const stringifySuppliers = (list, state) => {
        return list.filter( (_, idx) => state[idx] );
    };

    const suppliers = ["DWR", "EQ3", "RH", "CB2", "C&B"];
    const [suppliersCheckedState, setSuppliersCheckedState] = useState(
        new Array(suppliers.length).fill(true)
      );

    useEffect(()=> {
        props.setSuppliers(stringifySuppliers(suppliers, suppliersCheckedState))
    } ,[])

    const handleOnChange = (position) => {
        const updatedCheckedState = suppliersCheckedState.map((item, index) =>
          index === position ? !item : item
        );

        setSuppliersCheckedState(updatedCheckedState);
        props.setSuppliers(stringifySuppliers(suppliers, updatedCheckedState));
      };

    return  <div className="col">
                <div className="dropdown">
                    <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Supplier
                    </button>
                    <div className="dropdown-menu">
                        {suppliers.map((sup_name, index) => {
                            return (<div className="dropdown-item" key={index}>
                                <input
                                    type="checkbox"
                                    id={`custom-checkbox-${index}`}
                                    name={sup_name}
                                    value={sup_name}
                                    checked={suppliersCheckedState[index]}
                                    onChange={() => handleOnChange(index)}
                                    onKeyPress={props.handleKeypress}
                                />
                                <label className="px-2" htmlFor={`custom-checkbox-${index}`}>{sup_name}</label>
                            </div>)
                        })}
                    </div>
                </div>
            </div>
}


function PriceFilter(props) {

    return  <div className="col">
                <div className="dropdown">
                    <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Price
                    </button>
                    <div className="dropdown-menu px-3 price-dropdown-menu">
                        <div className="row d-flex">
                            <div className="col-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">$</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Min" aria-label="Min" aria-describedby="basic-addon1"
                                           pattern="[0-9]*"
                                           value={props.minPrice}
                                           onChange={(e) => props.setminPrice((v) => (e.target.validity.valid ? e.target.value : v))}
                                           onKeyPress={props.handleKeypress}/>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">$</span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Max" aria-label="Max" aria-describedby="basic-addon1"
                                           pattern="[0-9]*"
                                           value={props.maxPrice}
                                           onChange={(e) => props.setmaxPrice((v) => (e.target.validity.valid ? e.target.value : v))}
                                           onKeyPress={props.handleKeypress}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
}

export default function SearchBar(props) {
    let navigate = useNavigate();
    
    // Filters
    const [suppliersList, setSuppliers] = useState("");
    const [minPrice, setminPrice] = useState("");
    const [maxPrice, setmaxPrice] = useState("");
 
    // Search
    const [searchBarInput, setSearchBarInput] = useState('');
    const goToSearch = (searchValue) => {
        const data = {
            q: searchValue,
            supp: suppliersList,
            min_price: minPrice,
            max_price: maxPrice,
            start_with: 0,
            max_results: 32
        };

        const searchParams = new URLSearchParams(data);
        navigate("/search?" + searchParams);
    }

    const handleKeypress = e => {
        if (e.key === 'Enter') {
            goToSearch(searchBarInput);
        }
    };

    return (
        <>
            <section id="searchbar"  className="my-5">
                <div className="container">
                    <div className="row justify-content-around">
                    <div className="col-md-8 col-xl-5">

                        {/* Search Bar */}
                        <div className="input-group">
                            <input type="text" name="q" className="form-control" placeholder="what are you looking for ?" id="txtSearch" onKeyPress={handleKeypress} onChange={(e) => setSearchBarInput(e.target.value)}/>
                            <div className="input-group-btn">
                                <button className="btn btn-search" type="submit" onClick={() => goToSearch(searchBarInput)}>
                                    <span className="bi-search"> gg</span>
                                </button>
                            </div>
                        </div>

                        {/* Filters */}
                        <div className="row row-cols-auto">
                            <SupplierFilter setSuppliers={setSuppliers} handleKeypress={handleKeypress}/>
                            <PriceFilter minPrice={minPrice} setminPrice={setminPrice} maxPrice={maxPrice} setmaxPrice={setmaxPrice} handleKeypress={handleKeypress}/>
                        </div>
                    </div>
                    
                    <div className="col py-2 text-end">
                        { (Object.keys(props.searchResults).length > 0) && (window.location.href.includes("search")) ? // TODO: FIX this
                            <div> Showing {props.searchResults.start} - {props.searchResults.end} out of {props.searchResults.total} for "<span className="text-primary">{props.searchResults.query}</span>" </div>
                            : <> </>
                        }
                    </div>

                    </div>
                </div>
            </section>
           
            <Outlet />

        </>
    )
}
