import React from 'react';

import pic1 from "../assets/pictures/home_pic1.png"
import pic2 from "../assets/pictures/home_pic2.png"
import pic3 from "../assets/pictures/home_pic3.png"
import pic4 from "../assets/pictures/home_pic4.png"
import pic5 from "../assets/pictures/home_pic5.png"

export default function HomePage() {

    return (
        <section id="home-page">
            <div className="container">
                <h2> Your search for furniture starts here ! </h2>
                
                <div className="row my-5 align-items-center">
                    <div className="col-md-5"> <big> We are a furniture comparison marketplace with trusted suppliers, unbiased experts and AI. </big> </div>
                    <div className="col-md-7"> <img className="img-fluid" src={pic1} alt="Logo" /> </div>
                </div>

                <div className="row my-5 align-items-center ">
                    <div className="col-md-7"> <img className="img-fluid" src={pic2} alt="Logo" /> </div>
                    <div className="col-md-5"> <big> We scan through hundreds of supplier websites so you don't have to! Saving you time and money. </big> </div>
                </div>

                <div className="row my-5 align-items-center ">
                    <div className="col-md-7"> <big> By using AI and machine learning, we craft search results based on your preferences. </big> <img className="img-fluid py-5" src={pic3} alt="Logo" /> </div>
                    <div className="col-md-5">  <img className="img-fluid" src={pic4} alt="Logo" /> </div>
                </div>

                <div className="row my-5 align-items-center ">
                    <div className="col-md-5"> <big> We are not after commissions. We earn advertising revenue based on clicks. This is how we stay unbiased. </big> </div>
                    <div className="col-md-7">  <img className="img-fluid" src={pic5} alt="Logo" /> </div>
                </div>

                <div className="row my-5 pb-5 align-items-center ">
                    <div className="col-md-6"> <h3> Join the revolution and start searching our inventory with one click. </h3> </div>
                    <div className="col-md-6"> 
                        <form>
                            <div className="mb-3">
                                <label htmlFor="exampleInputPassword1" className="form-label">Your Name</label>
                                <input type="password" className="form-control" id="exampleInputPassword1"/>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                                <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                            </div>
                            <div className="mb-3 form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                <label className="form-check-label" htmlFor="exampleCheck1"> I agree to receive promotional emails </label>
                            </div>
                            <button type="submit" className="btn btn-secondary">Submit</button>
                        </form>
                    </div>
                </div>

            </div>
        </section>
    )
}