import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from 'axios';  // Use fetch instead ( but this is norm as well)

import ErrorPage from "./ErrorPage";
import ProductCard from './ProductCard';
import {BASE_URL} from "../tools"


function PageChanger(props) {
    let navigate = useNavigate();

    const max_per_page = parseInt(props.searchParams.get("max_results"))
    const n_pages = Math.ceil(props.searchFoundTotal / max_per_page)
    const cur_page = Math.floor(parseInt(props.searchParams.get("start_with")) / max_per_page)

    const page_neigbours_count = 5
    const page_num_start = Math.max(0, cur_page - page_neigbours_count)
    const page_num_end   = Math.min(n_pages, cur_page + page_neigbours_count)

    const page_numbers = Array.from(new Array(page_num_end - page_num_start), (x, i) => i + page_num_start);

    const goToPage = (pageNum) => {
        props.searchParams.set("start_with", pageNum * max_per_page)
        navigate("/search?" + props.searchParams);
    }

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination text-body">
                
                <li className={"page-item " + (cur_page===0 ? "disabled" : "")}>
                    <button className="page-link" onClick={() => goToPage(0)} aria-label="First" disabled={cur_page===0}>
                        <span aria-hidden="true">&lt;&lt;</span>
                        <span className="sr-only"></span>
                    </button>
                </li>
                <li className={"page-item " + (cur_page===0 ? "disabled" : "")}>
                    <button className="page-link" onClick={() => goToPage(Math.max(cur_page - 1, 0))} aria-label="Previous"  disabled={cur_page===0}>
                        <span aria-hidden="true">&lt;</span>
                        <span className="sr-only"></span>
                    </button>
                </li>

                {
                    page_numbers.map((page_idx) => {
                        if (page_idx === cur_page) {
                            return (<li className="page-item active"><span className="page-link">{page_idx + 1}</span></li>)
                        }
                        return (<li className="page-item"><button className="page-link" onClick={() => goToPage(page_idx)}> {page_idx + 1} </button></li>)
                    })
                }

                <li className={"page-item " + ( (cur_page===n_pages-1) ? "disabled" : "")}>
                    <button className="page-link" onClick={() => goToPage(Math.max(cur_page + 1, 0))} aria-label="Next"  disabled={cur_page===n_pages - 1}>
                        <span aria-hidden="true">&gt;</span>
                        <span className="sr-only"></span>
                    </button>
                </li>

                <li className={"page-item " + ( (cur_page===n_pages-1) ? "disabled" : "")}>
                    <button className="page-link" onClick={() => goToPage(n_pages - 1)} aria-label="Last" disabled={cur_page===n_pages - 1}>
                        <span aria-hidden="true">&gt;&gt;</span>
                        <span className="sr-only"></span>
                    </button>
                </li>

            </ul>
        </nav>
    )
}

function ProductsList(props) {

    const [searchStatus, setSearchStatus] = useState('loading');
    const [foundData, setFoundData] = useState([]);
    const [searchFoundTotal, setsearchFoundTotal] = useState(0);
    let [searchParams,] = useSearchParams();

    let searchUPDCallback = props.searchUpd;

    useEffect(() => {
        setSearchStatus("loading");

        axios.get(BASE_URL + "/search?" + searchParams)
            .then((response) => {

                // debug_slow_down();

                setFoundData(response.data.results)
                setsearchFoundTotal(response.data.found)

                if (response.data.results.length > 0) {
                    setSearchStatus("display")
                } else {
                    setSearchStatus("empty")
                }

                searchUPDCallback({
                    start: searchParams.get("start_with"),
                    end: parseInt(searchParams.get("start_with")) + response.data.results.length,
                    total: response.data.found,
                    query: searchParams.get("q"),
                })
        });

    }, [searchParams, searchUPDCallback]);

    switch (searchStatus) {
        case 'loading':
            return (
                <section id="search-results">
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border mx-2" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </section>
            );
        case 'empty':
                return (
                <section id="search-results">
                    <div className="d-flex justify-content-center">
                            Unfortunately we did not find anything on your query.
                            Please try again !
                    </div>
                </section>
            );
        case 'display':
            return (
            <section id="search-results">
                <div className="container py-5">
                    <div className="row">
                        {foundData.map((item) => { return ( 
                            <ProductCard item={item} key={item._id} /> 
                        ) }) }
                    </div>
                </div>
                <div className="container py-1 d-flex justify-content-center">
                    {   ( searchFoundTotal > parseInt(searchParams.get("max_results")) ) ?
                            <PageChanger searchParams={searchParams} searchFoundTotal={searchFoundTotal}/> : <> </>
                    }
                </div>
                <div className="container py-4"></div>
            </section>
        );

        default:
            return (
                <ErrorPage />
            );
    }

}

export default ProductsList;

