import React  from 'react';

function ErrorPage(props) {

   return (
    <section id="error-page">
        <div className="container text-center">
            <h3 className="py-5"> Oops! Something went wrong =( </h3>
        </div>
    </section>
    );
}

export default ErrorPage;

